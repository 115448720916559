<template>
  <div class="containers flex flex-direction">
    <el-row style="width: 100%">
      <el-col :span="24" class="header-pic">
        <el-image
          :src="require('@/assets/img/teamIntroduction/team_bg.png')"
          fit="cover"
          style="width: calc(100% + 1rem); height: 100%; z-index: 0"
        ></el-image>
      </el-col>
    </el-row>
    <!-- <div class="content1 self-fixedWidth">
      <el-image
        :src="require('@/assets/img/teamIntroduction/team_count.png')"
        fit="fill"
        style="width: 100%; height: 100%; z-index: 0"
      ></el-image>
    </div> -->
    <div class="coreGroupBox self-fixedWidth">
      <el-image class="coreGroupImg" :src="require('@/assets/img/teamIntroduction/coreGroupImg_1.png')" fit="fill"></el-image>
    </div>
    <div class="self-fixedWidth">
      <div class="presidentBox">
        <div class="presidentRight">
          <el-image class="presidentImg" :src="require('@/assets/img/teamIntroduction/coreGroupImg_2.png')" fit="cover"></el-image>
        </div>
        <div class="presidentLeft">
          <div class="presidentName">缪金生</div>
          <div class="presidentTitle">中科心客创始人、CEO</div>
          <div class="presidentText">南昌市政协委员，江西财经大学全日制博士、客座教授、兼职硕导，江西财经大学MBA创新创业协会会长，曾获“中国MBA杰出人物”、“2016年江西省年度经济人物金融创新奖”、“第七届江西省十大经济人物”等荣誉。</div>
        </div>
      </div>
      <el-row style="width: 100%" :gutter="20">
        <el-col :span="8" class="groupLi" v-for="(res,index) in groupList" :key="index">
          <div :class="index == 5?'groupLiImgBox actBot':'groupLiImgBox'">
            <el-image class="groupLiImg" :src="require('@/assets/img/teamIntroduction/coreGroupImg_'+(index + 3)+'.png')" fit="fill"></el-image>
          </div>
          <div class="groupName">{{res.name}}</div>
          <div class="groupPosition">{{res.position}}</div>
          <div class="groupIntroduce">{{res.text}}</div>
        </el-col>
      </el-row>




    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      groupList: [
        {
          id: "1",
          name: "李晓鹏",
          position: "副总裁",
          text: "江西财经大学工商管理硕士、兼职硕导,曾任纵捷科技CEO,从事软件技术开发工作18年"
        },
        {
          id: "2",
          name: "陈曦",
          position: "副总裁",
          text: "曾在海汇投资和南昌创投等多个国内知名投资机构担任投资总监，主导和参与多个企业的投资和上市工作"
        },
        {
          id: "3",
          name: "贺玉亮",
          position: "副总裁",
          text: "毕业于西南大学法学院，在金德集团等上市企业从事政务管理工作10余年，具有丰富的上市公司政务工作经验"
        },
        {
          id: "4",
          name: "雷川",
          position: "副总裁",
          text: "从业10余年，专注于科技赋能打造品牌建设升级，服务范围涵盖政府、高校、创投企业等多个不同领域。"
        },
        {
          id: "5",
          name: "吴迪",
          position: "副总裁",
          text: "从业10多年，专注于公司的投融资服务，公司上市辅导、并购以及破产重组等企业全生命周期精品投行服务。"
        }
        // {
        //   id: "6",
        //   name: "孟刚",
        //   position: "副总裁",
        //   text: "从业20多年，专注于传媒服务，先后负责过CCTV-1《新闻联播》、CCTV-3《开心辞典》、《星光大道》、《东方时空》等大型栏目运营。曾包盘湖北卫视经营流水5个亿，并长期保持与各大卫视的良好合作关系."
        // }
      ]
    };
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
::v-deep .el-button--warning {
  background: #f08519;
  border-color: #f08519;
}

.padding-lr-20 {
  padding: 0 15vw;
}

.containers {
  // margin-top: -1rem;
  // margin-bottom: -1rem;
  // margin-top: 2px;

  /* 具体内容 */
  .content1 {
    margin-top: -2rem;
    width: 100%;
    height: 100%;
  }
}
.coreGroupBox{
  margin: -2rem auto 2rem auto !important;
  width: 100%;
  height: 10.88rem;
  background: #ffffff;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.coreGroupImg{
  width: 42.13rem;
  height: 4.94rem;
  z-index: 0
}
.presidentBox{
  width: 100%;
  height: 26.25rem;
  margin-bottom: 7rem;
}
.presidentLeft{
  float: left;
  width: calc(100% - 35rem);
  height: 100%;
  padding: 6rem 0 0 4rem;
}
.presidentRight{
  float: right;
  width: 31rem;
  height: 26.25rem;
}
.presidentImg{
  width: 22.25rem;
  height: 26.25rem;
}
.presidentName{
  font-size: 1.75rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  color: #f08519;
}
.presidentTitle{
  font-size: 1.25rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #333333;
  margin: 0.5rem 0 1.5rem 0;
}
.presidentText{
  font-size: 1rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #333333;
  line-height: 2rem;
}
.groupLi{
  margin-bottom: 7rem;
}
.groupLiImgBox{
  height: 12.13rem;
  margin-bottom: 1.5rem;
  text-align: center;
}
.groupLiImg{
  width: 12.13rem;
  height: 12.13rem;
  border-radius: 50%;
}
.groupName{
  font-size: 1.5rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: center;
  color: #f08519;
}
.groupPosition{
  font-size: 1.25rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: center;
  color: #333333;
  margin: 0.5rem 0 1rem 0;
}
.groupIntroduce{
  font-size: 1rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: center;
  color: #333333;
  line-height: 2rem;
  padding: 0 20px;
}
.actBot{
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 1200px) {
  .coreGroupBox{
    margin: -2rem auto 2rem auto !important;
    height: 5rem;
  }
  .coreGroupImg{
    width: 95%;
    height: auto;
    z-index: 0
  }
  .presidentBox{
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 2rem;
  }
  .presidentLeft{
    width: 100%;
    padding: 20px 10px 0 10px;
  }
  .presidentRight{
    width: 100%;
    height: auto;
    text-align: center;
  }
  .presidentImg{
    width: 200px;
    height: auto;
  }
  .groupLi{
    width: 100%;
    margin-bottom: 2rem;
  }
  .actBot{
    margin-bottom: 1.5rem;
  }
}
@media screen and (max-width: 764px) {
  .self-wrap-class {
    .el-main {
      .container {
        width: 100% !important;
        min-width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        .self-fixedWidth {
          width: 100% !important;
          min-width: 100% !important;
          margin: 0 !important;
          padding: 0 !important;
        }
        .content1 {
          margin-top: -2rem;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .self-wrap-class {
    .el-main {
      .container {
        .content {
          .brief-introduction-box {
            padding: 2rem;
          }

          .student-container {
            grid-template-columns: repeat(1, 100%);
            grid-gap: 0;

            .excellent-students-card {
              flex-wrap: nowrap !important;
              height: auto;
              padding: 1rem 0;

              // .student-img{
              //   width: 60%;
              // }
            }
          }
          .star-tutor-container {
            grid-template-columns: repeat(1, 100%);
            .star-tutor-box {
              .star-tutor-img {
                width: 100%;
              }

              .star-tutor-brief {
                padding: 0.5rem;

                span {
                  padding: 0.5rem 1rem;
                }
              }
            }
          }

          .course-module-box {
            padding-left: 1rem;
            .course-item {
              padding-left: 0;
              height: auto;

              .course-module-title {
                font-size: 1rem;
              }
            }
          }
          .course-benefits-container {
            grid-template-columns: repeat(1, 100%);
          }
          .registration-method-box {
            height: auto;
            padding: 2rem;
            .form-row {
              flex-wrap: wrap !important;
              .form-item {
                width: 108%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
